import React from 'react';
import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';

import { border } from '../utils/sharedStyles';

import {
  Content,
  ContentBlock,
  ContentElement,
  Headline,
  List,
} from '../components';

import { services } from '../data';

const Graphic = styled.img`
  margin-bottom: 3rem;
  max-width: 220px;
`;

const Text = styled.p`
  font: normal normal 300 16px/28px Heebo;
`;

const Subline = styled.h3`
  font: normal normal 600 20px/28px Heebo;
  margin-bottom: 0.5rem;
`;

const StyledGrid = styled(Grid)`
  flex-grow: 1;
`;

const ElementWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Services = () => {
  const borders = {
    borderRight: { xs: 'none', md: border },
    borderBottom: { xs: border, md: 'none' },
  };
  const spacing = {
    py: 3,
    px: 5,
  };
  return (
    <Content>
      <ContentBlock>
        <Grid container>
          {services.map((service, index) => {
            const notLastElement = index < services.length - 1;
            return (
              <StyledGrid item sm={12} md={4} key={service.id}>
                <ContentElement
                  {...(notLastElement && { ...borders })}
                  {...spacing}
                  py={8}
                >
                  <Graphic alt="mixer" src={service.icon} />
                  <Headline semiBold>{service.headline}</Headline>
                  {service.elements.map(element => (
                    <ElementWrapper key={element.id}>
                      {element?.headline && (
                        <Subline>{element.headline}</Subline>
                      )}
                      {element?.list && (
                        <List items={element.list} fontSize="16px/28px" />
                      )}
                      {element?.paragraph && (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: element.paragraph,
                          }}
                        />
                      )}
                    </ElementWrapper>
                  ))}
                </ContentElement>
              </StyledGrid>
            );
          })}
        </Grid>
      </ContentBlock>
    </Content>
  );
};

export default Services;
